$(document).ready(function() {

    // Переделать на fotorama
    if ($('.opening_slideshow').length) {
        var index = 0;
        var slides = $('.opening_slideshow_image');
        var classHide = 'disabled', count = slides.length;

        function nextSlide() {
            slides[(index ++) % count].classList.add(classHide);
            slides[index % count].classList.remove(classHide);
            setTimeout(nextSlide, 3000);
        }
        nextSlide();
    }

    var cur_lang = $("#current-language").data("lang");

    // Slider
    if ($().slick) {
        $('.index-promotion__slider').slick({
            lazyLoad: 'progressive',
            customPaging: true,
            autoplay: true,
            speed: 500,
            fade: true,
            arrows: true
        });

        $('.index-promotion__slider').on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
            //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
            var i = (currentSlide ? currentSlide : 0) + 1;
            $(".slider-number").text(i + '/' + slick.slideCount);
        });
    }

    // Special projects slider
    if ($().flexslider) {
        $('.flexslider#special').flexslider({
            touch: true,
            animation: 'fade',
            slideshow: true,
            slideshowSpeed: 4000,
            pauseOnHover: true,
            prevText: "",
            nextText: ""
        });
    }



    // Reduce font size for large quotes
    function reduceQuoteSize(el,size) {
        $(el).each(function(){
            newsContent= $(this),
                newsText = newsContent.text();
            //if(newsText.length > size) newsContent.text(newsText.slice(0, size) + '...');
            if(newsText.length > size) newsContent.css({ 'font-size': '18px' });
        });
    }
    reduceQuoteSize('.cite_text',250);


    /*$('.catalogue_search_wrapper img').on('click', function() {
        $(this).toggleClass('active');
        $('.index_search_select').toggleClass('active');
    });*/



    /**/

    $("#mobile_search_button").click(function(e){
        var q = $(this).val();
        if (q.length && e.which==13) {
            var search_url = 'http://catalog.libfl.ru/Search/Results?';
            search_url += 'lookfor=' + encodeURI(q) + '&type=AllFields';
            window.location = search_url;
        }
    });
    $("#mobile_search").keypress(function(e){
        var q = $(this).val();
        if (q.length && e.which==13) {
            var search_url = 'http://catalog.libfl.ru/Search/Results?';
            search_url += 'lookfor=' + encodeURI(q) + '&type=AllFields';
            window.location = search_url;
        }
    });





    /*function changeCatalogueSearchPlaceholder() {
        var placeholders = ['James Joyce', 'Les Misérables', 'The Great Gatsby', 'Götz von Berlichingen', 'Золотой телёнок', 'Il gattopardo', 'Antoine de Saint-Exupéry', 'Françoise Sagan', 'Доктор Живаго'];
        $("#index_search").attr('placeholder', placeholders[Math.floor(Math.random()*placeholders.length)]);
    }*/

    /*changeCatalogueSearchPlaceholder();*/

    /*$("#index_search").keypress(function(e){
        var q=$(this).val()
        if (q.length && e.which==13) {
            $("#index_search_form").submit();
        }
    })*/

    /*$('.button__filled').click(function(e){
        var q = $('#index_search').val();
        if (q.length) {
            var search_url = 'http://catalog.libfl.ru/Search/Results?';
            switch ($(q).attr('data-filters')) {
                case 'author':
                    search_url += 'sort=relevance&join=AND&lookfor0%5B%5D=' + encodeURI(q) + '&type0%5B%5D=Author&bool0%5B%5D=AND&illustration=-1&daterange%5B%5D=publishDate';
                    break;
                case 'title':
                    search_url += 'sort=relevance&join=AND&lookfor0%5B%5D=' + encodeURI(q) + '&type0%5B%5D=Title&bool0%5B%5D=AND&illustration=-1&daterange%5B%5D=publishDate';
                    break;
                case 'subject':
                    search_url += 'sort=relevance&join=AND&lookfor0%5B%5D=' + encodeURI(q) + '&type0%5B%5D=Subject&bool0%5B%5D=AND&illustration=-1&daterange%5B%5D=publishDate';
                    break;
                case 'isbn':
                    search_url += 'sort=relevance&join=AND&lookfor0%5B%5D=' + encodeURI(q) + '&type0%5B%5D=ISN&bool0%5B%5D=AND&illustration=-1&daterange%5B%5D=publishDate';
                    break;
                default:
                    search_url += 'lookfor=' + encodeURI(q) + '&type=AllFields';
                    break;
            }
            window.location = search_url;
        }
    });*/

    /*$("#index_search").keypress(function(e){
        var q = $(this).val();
        if (q.length && e.which==13) {
            var search_url = 'http://catalog.libfl.ru/Search/Results?';
            switch ($(this).attr('data-filters')) {
                case 'author':
                    search_url += 'sort=relevance&join=AND&lookfor0%5B%5D=' + encodeURI(q) + '&type0%5B%5D=Author&bool0%5B%5D=AND&illustration=-1&daterange%5B%5D=publishDate';
                    break;
                case 'title':
                    search_url += 'sort=relevance&join=AND&lookfor0%5B%5D=' + encodeURI(q) + '&type0%5B%5D=Title&bool0%5B%5D=AND&illustration=-1&daterange%5B%5D=publishDate';
                    break;
                case 'subject':
                    search_url += 'sort=relevance&join=AND&lookfor0%5B%5D=' + encodeURI(q) + '&type0%5B%5D=Subject&bool0%5B%5D=AND&illustration=-1&daterange%5B%5D=publishDate';
                    break;
                case 'isbn':
                    search_url += 'sort=relevance&join=AND&lookfor0%5B%5D=' + encodeURI(q) + '&type0%5B%5D=ISN&bool0%5B%5D=AND&illustration=-1&daterange%5B%5D=publishDate';
                    break;
                default:
                    search_url += 'lookfor=' + encodeURI(q) + '&type=AllFields';
                    break;
            }
            window.location = search_url;
        }
    });*/

    if ($('.index_centers_wrapper').length || $('.index_columns').length) {
        $('.content').addClass('index_content');
    }



    window.dateChanged = false;
    $('body').on('change', '.end_date, .start_date', function() {
        window.dateChanged = true;
    });

    if ($('input[type="radio"], input[type="checkbox"]').length)
        $('input').iCheck({
            checkboxClass: 'icheckbox_minimal',
            radioClass: 'iradio_minimal'
        });

    /*var D = new Date();
     var Day = D.getDate().toString().length == 1 ? '0' + D.getDate() : D.getDate();
     var Month = (D.getMonth() + 1).toString().length == 1 ? '0' + (D.getMonth() + 1) : (D.getMonth() + 1);
     var hash = window.location.hash;
     if (typeof hash != 'undefined' && hash.length > 1) {
     var type = JSON.parse(hash.substr(1)).type;
     if (type == 'report' || type == 'news') {
     $('input.start_date').val(Day + '.' + Month + '.' + (D.getFullYear() - 1));
     $('input.end_date').val(Day + '.' + Month + '.' + D.getFullYear());
     } else {
     $('input.start_date').val(Day + '.' + Month + '.' + D.getFullYear());
     $('input.end_date').val(Day + '.' + Month + '.' + (D.getFullYear() + 1));
     }
     } else {
     $('input.start_date').val(Day + '.' + Month + '.' + D.getFullYear());
     $('input.end_date').val(Day + '.' + Month + '.' + (D.getFullYear() + 1));
     }*/

    $('.input-daterange').addClass('selected');

    function resizeRecalc() {
        $('select').selectmenu({
            select: function(event, ui) {
                $(this).trigger('change')
                if ($(this).val()) {
                    $(this).siblings('.ui-selectmenu-button').addClass('ui-selected');
                } else {
                    $(this).siblings('.ui-selectmenu-button').removeClass('ui-selected');
                }
            }
        });
        if (window.innerWidth < 700) {
            $('.event_image_wrapper, .inner_page_teaser_image').css('opacity', 0);
            setTimeout(function() {
                $('.event_image_wrapper, .inner_page_teaser_image').css('width', $('body').width()).css('opacity', 1);
            }, 600);
        } else {
            $('.event_image_wrapper, .inner_page_teaser_image').css('width', 'auto');
        }
        if (window.innerWidth < 760) {
            $('.listing_wrapper, .filters_wrapper, .switcher_wrapper').removeClass('list_type');
            if ($('.about_block').length) {
                $('.about_block span').each(function(i,e) {
                    $(e).html($(e).html().replace(/-<br>/g, '-').replace(/<br>/g, ' '));
                });
            }
        }
    }

    resizeRecalc();
    $(window).on('resize', function() {
        resizeRecalc();
    });

    if (window.location.hash) {
        try {
            var url = window.location.hash.substr(1);
            var decodeUrl = decodeURIComponent(url);
            var hash = JSON.parse(decodeUrl);
            if (hash.category) {
                $('#filter_category').val(hash.category).trigger('change').selectmenu('refresh');
                $('#filter_category-button').addClass('ui-selected');
            }
            if (hash.news) {
                $('#filter_department').val(hash.news).trigger('change').selectmenu('refresh');
                $('#filter_department-button').addClass('ui-selected');
            }
            if (hash.collection) {
                $('#collection_filter').val(hash.collection).trigger('change').selectmenu('refresh');
                $('#collection_filter-button').addClass('ui-selected');
            }
            if (hash.isPushkin) {
                $('input[name="isPushkin"]').prop('checked', hash.isPushkin).parent().addClass('checked');
            }
            if (hash.donbass) {
                $('input[name="donbass"]').prop('checked', hash.donbass).parent().addClass('checked');
            }
            if (hash.cycle) {
                $('input[name="cycle"]').val(hash.cycle);
            }
        } catch (err) {
            console.log(err);
        }
    }



    $('.input-daterange').datepicker({
        format: "dd.mm.yy",
        weekStart: 1,
        language: "ru"
    });
    $('#events_date_start').datepicker({
        format: "dd.mm.yy",
        weekStart: 1,
        language: "ru",
        focusOnShow: false,
        keepOpen: false
    }).on('change', function() {
        if ($(this).val()) {
            $('.show-more').data('s', 30);
            $('#picker-container').hide();
            $('.events_today_btn').removeClass('active');
            $('.events_cal_btn').addClass('active');
            $('.clear_events_date').show();
            $('.datepicker.dropdown-menu').hide();
        }
    });
    $('.events_cal_btn').on('click', function(e) {
        if ($(e.target).hasClass('clear_events_date')) return;
        $('#picker-container').show();
        $('#events_date_start').data("datepicker").show()
    });
    $('.events_today_btn, .clear_events_date').on('click', function() {
        $('.show-more').data('s', 0);
        $('.events_cal_btn').removeClass('active');
        $('.events_today_btn').addClass('active');
        $('#events_date_start').val('').trigger('change');
        $('.clear_events_date').hide();
    });
    $('#date_input').datepicker({
        format: "dd.mm.yyyy",
        weekStart: 1,
        language: "ru",
        focusOnShow: false,
        keepOpen: false
    }).on('change', function() {
        if ($(this).val()) {
            $('.show-more').data('s', 0);
            $('#picker-container').hide();
            $('.datepicker.dropdown-menu').hide();
        }
        if ($(this).siblings('.remove_date').length) {
            if ($(this).val()) {
                $(this).siblings('.remove_date').show();
                $(this).parent().addClass('active');
            } else {
                $(this).siblings('.remove_date').hide();
                $(this).parent().removeClass('active');
            }
        }
    });
    $('#date_btn').on('click', function() {
        $('#picker-container').show();
        $('#date_input').data("datepicker").show()
    });
    $('#picker-container').on('click', function(e) {
        if ($(e.target).attr('id') == 'picker-container') $('#picker-container').hide();
    });
    /*if ($('.fotorama').length) {
        var f_width = '100%';
        var a_width  = 81;
        if ($('.inner_navigation_text').length) {
            if (window.innerWidth < 760) a_width = 30;
            f_width = $('.inner_navigation_text').width() - a_width*2;
        }
        $('.fotorama').fotorama({
            width: f_width,
            maxWidth: '777px',
            nav: false,
            transition: 'crossfade',
            arrows: 'always',
            allowfullscreen: 'true',
            loop: true
        }).on('fotorama:showend fotorama:ready', function(e, fotorama, extra) {
            var from = fotorama.size;
            var slide = fotorama.activeFrame.i;
            if (from > 1)
                $('.fotorama_counter').text(slide + ' из ' + from);
        });
    }*/

    if ($('.linked_doc').length) {
        $('.linked_doc_title span').each(function(i,e) {
            if ($(e).height() < 90) {
                $(e).parents('.linked_doc').addClass('small_doc');
            }
        });
    }
    /*var Host = new RegExp(window.location.hostname, 'g');
    $('a').each(function(i,e) {
        var href = $(e).attr('href');
        var is_url = /http/.test(href);
        var is_inner = Host.test(href);
        if (is_url && !is_inner) {
            $(e).attr('target', '_blank');
        }
    });*/


    var Banners_current_page = 0;
    //var Banners_blocks_by_page = window.innerWidth <= 745 ? 1 : 3;
    var Banners_wrapper = $('#index_banners_inner');
    var Banners_count = 0;
    var pins_html = '<div class="learning_show_pin active"></div>';
    var Banners_count = Banners_wrapper.find('a').length;
    var pinsCount = Banners_count;
    for (var i = 1; i < pinsCount; i++) {
        pins_html += '<div class="learning_show_pin"></div>';
    }
    $('#index_show_pins').html(pins_html);

    $('#index_banners_wrapper .learning_show_pin').on('click', function() {
        if ($(this).hasClass('active')) return;
        $(this).addClass('active').siblings().removeClass('active');
        Banners_current_page = $(this).index();
        IndexShowswitchToSlide(Banners_current_page);
        clearInterval(indexShowInterval);
    });
    function IndexShowswitchToSlide(slide) {
        $('#index_banners_wrapper .learning_show_pin').eq(slide).addClass('active').siblings().removeClass('active');
        var block = Banners_wrapper.find('a');
        var Banners_width = block.width();
        var recommend_blocks_length = Banners_wrapper.find('a').length;
        Banners_wrapper.css('transform', 'translateX(' + -(slide * Banners_width) + 'px)');
    }
    var indexShowInterval = setInterval(function() {
        if (Banners_current_page < pinsCount - 1)
            Banners_current_page++;
        else
            Banners_current_page = 0;
        if (window.innerWidth > 775)
            IndexShowswitchToSlide(Banners_current_page);
    }, 4000);


    var pins_html = '';
    var bannerShow;
    var showAnimTime = 400;
    $('.learning_show_slides a').each(function(i,e) {
        if (i == 0) {
            $(e).css({
                'opacity': 1,
                'z-index': 10
            });
            pins_html += '<div class="learning_show_pin active"></div>';
        } else {
            $(e).css('opacity', 0);
            pins_html += '<div class="learning_show_pin"></div>';
        }
    });
    if ($('.learning_show_slides a').length > 1) {
        $('.learning_show_pins').html(pins_html);
        bannerShow = setInterval(function () {
            var ind = $('.learning_show_pin.active').index();
            $('.learning_show_slides a').eq(ind).animate({'opacity': 0}, showAnimTime).css('z-index', 1);
            if ($('.learning_show_pin.active').next().length) {
                $('.learning_show_pin.active').removeClass('active').next().addClass('active');
                $('.learning_show_slides a').eq(ind + 1).animate({'opacity': 1}, showAnimTime).css('z-index', 10);
            } else {
                $('.learning_show_pin.active').removeClass('active');
                $('.learning_show_pin').first().addClass('active');
                $('.learning_show_slides a').eq(0).animate({'opacity': 1}, showAnimTime).css('z-index', 10);
            }
        }, 4000);


    }

    /* logo randomizer */

    function logoRandomizer() {
        var logoVariations = ['/images/svg/accent-logo.svg', '/images/svg/acute-logo.svg', '/images/svg/breve-logo.svg', '/images/svg/circumflex-logo.svg', '/images/svg/tilde-logo.svg', '/images/svg/umlaut-logo.svg'];
        var randomNum = Math.floor(Math.random() * logoVariations.length);
        document.getElementById("random").src = logoVariations[randomNum];
    }
    //logoRandomizer();

    /**/

    function backShowBlock(elem, time) {
        setTimeout(function() {
            elem.removeClass('moved moved_back');
        }, time);

    }
    function recalcShowArrows() {
        var visible = Math.round($('.blocks_show_wrapper').width()/($('.float_small_block').width() + parseInt($('.float_small_block').css('margin-right'))));
        var active = $('.inner_wrapper.active');
        var len = Math.floor((active.find('.float_small_block').length - 1)/visible);
        var offset = parseInt(active.attr('data-offset'));
        if (offset > 0) $('.blocks_show_prev').removeClass('disabled');
        if (len != 0) $('.blocks_show_next').removeClass('disabled');
        if (offset == len) $('.blocks_show_next').addClass('disabled');
        if (offset == 0) $('.blocks_show_prev').addClass('disabled');
    }
    function recalcRecommendArrows() {
        var visible = Math.round($('.recommend_content').width()/($('.recommend_block').width()));
        var w = $('.recommend_block').width() * visible;
        var active = $('.recommend_inner');
        var len = Math.floor((active.find('.recommend_block').length - 1)/visible);
        var offset = parseInt(active.attr('data-offset'));
        if (offset > 0) $('.recommend_arrow_left').removeClass('disabled');
        if (len != 0) $('.recommend_arrow_right').removeClass('disabled');
        if (offset == len) $('.recommend_arrow_right').addClass('disabled');
        if (offset == 0) $('.recommend_arrow_left').addClass('disabled');
    }
    function recalcLongTermArrows() {
        concole.log(93);
        var visible = Math.round($('.long-term-events').height()/($('.long-term-events__item').height()));
        var w = $('.long-term-events__item').height() * visible;
        var active = $('.long-term-events__inner');
        var len = Math.floor((active.find('.recommend_block').length - 1)/visible);
        var offset = parseInt(active.attr('data-offset'));
        //if (offset > 0) $('.js-arrow-up').removeClass('disabled');
        //if (len != 0) $('.js-arrow-down').removeClass('disabled');
        //if (offset == len) $('.js-arrow-down').addClass('disabled');
        //if (offset == 0) $('.js-arrow-up').addClass('disabled');
    }
    function printDiv(divName) {
        var printContents = document.getElementById(divName).innerHTML;
        var originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
    }

    if ($('.index_float_blocks .float_block').length < 5) {
        $('.index_float_blocks').removeClass('wide_content').addClass('content');
    }
    $('.index_float_blocks').show();
    /*if ($('.banners_show_inner .column_banner').length < 4) {
        $('.banners_show_controls').hide();
    }*/

    if ($('.banners_show_inner .column_banner').length < 4) {
        $('.banners_show_controls').hide();
    }

    if ($('.etage_wrapper').length) {
        var sel, img;
        $('.etage_wrapper').each(function(i,e) {
            $(e).find('.section_switch_block').first().addClass('active');
            sel = $(e).find('.section_switch_block').first().attr('data-selector');
            img = $(sel).find('.section_image_path').html();
            $(e).find('.etage_section').attr('src', img);
            if ($(sel).find('.section_info_wrapper').length) {
                var info = $(sel).find('.section_info_wrapper').html();
                $(e).find('.section_info_content').html(info);
                $(e).find('.etage_section_info_btn').addClass('active');
            } else {
                $(e).find('.etage_section_info_btn').removeClass('active');
            }
        });
        sel = $('.etage_wrapper.active .section_switch_block.active').attr('data-selector');
        if ($(sel).find('.section_info_wrapper').length) {
            var info = $(sel).find('.section_info_wrapper').html();
            $('.etage_wrapper.active').find('.section_info_content').html(info);
            $('.etage_wrapper.active').find('.etage_section_info_btn').addClass('active');
        }
    }
    if (window.innerWidth < 900) {
        $('.article-nav-childs__header').on('click', function(){
            $(this).parents('.article-nav-childs').toggleClass('active');
        })
    }
    if (window.innerWidth > 1100) {
        $('body').on('mouseenter', '.js-working-hours-hover, .working-hours', function() {
            $('.working-hours').show();
        }).on('mouseleave', '.js-working-hours-hover, .working-hours', function() {
            $('.working-hours').hide();
        });
    }
    if (window.innerWidth < 760) {
        $('.listing_wrapper, .filters_wrapper, .switcher_wrapper').removeClass('list_type');
    }

    if ($('#accordion').length) {
        $( "#accordion" ).accordion({
            heightStyle: "content",
            collapsible: true,
            active: false
        });
    }


    if (typeof Centers_Banners != 'undefined') {
        var Banner_rnd = Math.trunc(Math.random()*Centers_Banners.length);
        $(".cc_banner_image").css("background-image", "url(" + Centers_Banners[Banner_rnd] + ")");
        setInterval(function() {
            var i = Math.trunc(Math.random()*Centers_Banners.length);
            if (i == Banner_rnd && i == Centers_Banners.length) {
                i--;
            } else if (i == Banner_rnd) {
                i++;
            }
            Banner_rnd = i;
            $(".cc_banner_image").fadeOut(400, function() {
                $(this).css("background-image", "url(" + Centers_Banners[i] + ")").fadeIn(400);
            });
        }, 5000);

    }
    var Index_Btn_Href = $('a.index_other_events').attr('href');

    $('.event-content .btn_social, .collection_page_socials .btn_social, .inner_menu_share .inner_share_btn').each(function(i,e) {
        //$(e).attr('href', $(e).attr('href') + window.location.href)
        console.log(e);
        $(e).attr('href', $(e).attr('href').replace('%url%',  window.location.href))
    });
    if ($('meta[property="og:image"]').length) {
        //$('meta[property="og:image"]').attr('content', 'http://libfl.ru' + $('meta[property="og:image"]').attr('content'));
    }


    $('body').on('click', '*', function(e) {
        var t = $(e.target);
        if (!t.parents('.vf_search_form').length || t.attr('id') == 'search') {
            $('.vf_search_select').hide();
        }
        if (!t.hasClass('breadcrumb_current')) $('.breadcrumb_links').hide();
    }).on('touchend', '.hamburger', function() {
        $('#nav').toggleClass('active');
        $(this).toggleClass('is-active');
    }).on('click', '.switch_type', function() {
        $('.listing_wrapper, .filters_wrapper, .switcher_wrapper').toggleClass('list_type');
    }).on('change', '.end_date', function() {
        if ($(this).val() != '') {
            $(this).parent().addClass('selected');
        } else {
            $(this).parent().removeClass('selected');
        }
    }).on('click', '.switch_block', function() {
        var wrapper = $(this).parent().attr('data-selector');
        var selector = $(this).attr('data-active');
        $(this).parents('.switcher_wrapper').find('.switch_block').removeClass('active');
        $(this).addClass('active');
        $(wrapper).removeClass('active');
        $(selector).addClass('active');
    }).on('click', '.learning_show_pin', function() {
        clearInterval(bannerShow);
        var ind = $('.learning_show_pin.active').index();
        var nind = $(this).index();
        $('.learning_show_pin').removeClass('active');
        $(this).addClass('active');
        $('.learning_show_slides a').eq(ind).animate({'opacity' : 0}, showAnimTime).css('z-index', 1);
        $('.learning_show_slides a').eq(nind).animate({'opacity' : 1}, showAnimTime).css('z-index', 10);
    }).on('click', '.blocks_show_switcher', function() {
        var wrapper = $(this).parent().attr('data-selector');
        var selector = $(this).attr('data-active');
        var text = $(this).attr('data-text');
        var hash = $(this).attr('data-hash');
        $(this).parents('.blocks_show_switchers').find('.blocks_show_switcher').removeClass('active');
        $(this).addClass('active');
        $(wrapper).removeClass('active');
        $(selector).addClass('active');
        $('a.index_other_events').attr('href', Index_Btn_Href + '#' + hash).text(text);
        recalcShowArrows();
    }).on('click', '.blocks_show_next', function() {
        var visible = Math.round($('.blocks_show_wrapper').width()/($('.float_small_block').width() + parseInt($('.float_small_block').css('margin-right'))));
        var w = ($('.float_small_block').width() + parseInt($('.float_small_block').css('margin-right'))) * visible;
        var active = $('.inner_wrapper.active');
        var len = Math.floor((active.find('.float_small_block').length - 1)/visible);
        var offset = parseInt(active.attr('data-offset'));
        if (offset < len) {
            offset++;
            var a = 0;
            for (var i = visible * offset; i < visible * offset + visible; i++) {
                active.find('.float_small_block').eq(i).addClass('moved');
                backShowBlock(active.find('.float_small_block').eq(i), a * 200);
                a++;
            }
            active.attr('data-offset', offset);
        }
        recalcShowArrows();
        active.css({
            '-webkit-transform' : 'translateX(-'  + offset * w + 'px)',
            '-moz-transform' : 'translateX(-'  + offset * w + 'px)',
            '-ms-transform' : 'translateX(-'  + offset * w + 'px)',
            'transform' : 'translateX(-'  + offset * w + 'px)'
        });

    }).on('click', '.blocks_show_prev', function() {
        var visible = Math.round($('.blocks_show_wrapper').width()/($('.float_small_block').width() + parseInt($('.float_small_block').css('margin-right'))));
        var w = ($('.float_small_block').width() + parseInt($('.float_small_block').css('margin-right'))) * visible;
        var active = $('.inner_wrapper.active');
        var len = Math.floor((active.find('.float_small_block').length - 1)/visible);
        var offset = parseInt(active.attr('data-offset'));
        if (offset > 0) {
            offset--;
            var a = 0;
            for (var i = visible * offset + visible - 1; i > visible * offset - 1; i--) {
                console.log(i);
                active.find('.float_small_block').eq(i).addClass('moved_back');
                backShowBlock(active.find('.float_small_block').eq(i), a * 200);
                a++;
            }
            active.attr('data-offset', offset);
        }
        recalcShowArrows();
        active.css({
            '-webkit-transform' : 'translateX(-'  + offset * w + 'px)',
            '-moz-transform' : 'translateX(-'  + offset * w + 'px)',
            '-ms-transform' : 'translateX(-'  + offset * w + 'px)',
            'transform' : 'translateX(-'  + offset * w + 'px)'
        });

    }).on('click', '.recommend_arrow_right', function() {
        var visible = Math.round($('.recommend_content').width()/($('.recommend_block').width() + 1));
        var w = ($('.recommend_block').width() + 1) * visible;
        var active = $('.recommend_inner');
        var len = Math.floor((active.find('.recommend_block').length - 1)/visible);
        var offset = parseInt(active.attr('data-offset'));
        if (offset < len) {
            offset++;
            var a = 0;
            for (var i = visible * offset; i < visible * offset + visible; i++) {
                active.find('.recommend_block').eq(i).addClass('moved');
                backShowBlock(active.find('.recommend_block').eq(i), a * 200);
                a++;
            }
            active.attr('data-offset', offset);
        }
        recalcRecommendArrows();
        active.css({
            '-webkit-transform' : 'translateX(-'  + offset * w + 'px)',
            '-moz-transform' : 'translateX(-'  + offset * w + 'px)',
            '-ms-transform' : 'translateX(-'  + offset * w + 'px)',
            'transform' : 'translateX(-'  + offset * w + 'px)'
        });

    }).on('click', '.recommend_arrow_left', function() {
        var visible = Math.round($('.recommend_content').width()/($('.recommend_block').width() + 1));
        var w = ($('.recommend_block').width() + 1) * visible;
        var active = $('.recommend_inner');
        var len = Math.floor((active.find('.recommend_block').length - 1)/visible);
        var offset = parseInt(active.attr('data-offset'));
        if (offset > 0) {
            offset--;
            var a = 0;
            for (var i = visible * offset + visible - 1; i > visible * offset - 1; i--) {
                console.log(i);
                active.find('.recommend_block').eq(i).addClass('moved_back');
                backShowBlock(active.find('.recommend_block').eq(i), a * 200);
                a++;
            }
            active.attr('data-offset', offset);
        }
        recalcRecommendArrows();
        active.css({
            '-webkit-transform' : 'translateX(-'  + offset * w + 'px)',
            '-moz-transform' : 'translateX(-'  + offset * w + 'px)',
            '-ms-transform' : 'translateX(-'  + offset * w + 'px)',
            'transform' : 'translateX(-'  + offset * w + 'px)'
        });

    }).on('click', '.section_switch_block', function() {
        $(this).addClass('active').siblings('.section_switch_block').removeClass('active');
        var sel = $(this).attr('data-selector');
        var img = $(sel).find('.section_image_path').html();
        $(this).parents('.etage_wrapper').find('.etage_section').attr('src', img);
        if ($(sel).find('.section_info_wrapper').length) {
            var info = $(sel).find('.section_info_wrapper').html();
            $(this).siblings('.section_info').find('.section_info_content').html(info);
            $(this).parents('.etage_wrapper').find('.etage_section_info_btn').addClass('active');
        } else {
            $(this).parents('.etage_wrapper').find('.etage_section_info_btn').removeClass('active');
        }
    }).on('click', '.etage_section_info_btn', function() {
        $(this).toggleClass('selected');
        $(this).parents('.etage_wrapper').find('.section_info').toggleClass('active');
    }).on('click', '.scheme_switch_block', function() {
        var s = $(this).parents('.scheme_switcher').attr('data-selector');
        var a = $(this).attr('data-active');
        $(this).addClass('active').siblings().removeClass('active');
        $(s).removeClass('active');
        $(a).addClass('active');
    }).on('click', '.section_info_print', function() {
        printDiv('print_wrapper');
    }).on('click', '.section_info_close', function() {
        $(this).parents('.section_info').removeClass('active');
        $(this).parents('.etage_wrapper').find('.etage_section_info_btn').removeClass('selected');
    }).on('click', '.open_popup', function(e) {
        e.preventDefault();
        var el = $('#' + $(this).attr('data-id'));
        $('.popup_box').hide();
        if ($(this).data('book')) {
            var wrap = $('#popup_book');
            wrap.find('.popup_book_image').css('visibility', 'hidden');
            $.ajax({
                url: '/ajax/books?p=documents topImage&f={"_id":"'+$(this).data('book').replace(/"/g,'')+'"}',
                type: 'get',
                success: function (d) {
                    d = d[0];
                    var lang = $('#current-language').data('lang');
                    $('#popup_wrapper').show();
                    if (d) {
                        if (d.topImage) {
                            wrap.find('.popup_book_image').attr('src', '/attachments/' + d.topImage.attachment.preview.path).on('load', function() {
                                $(this).css('visibility', 'visible');
                            });
                        }
                        if (d.author && d.author[lang]) wrap.find('.collection_book_author').html(d.author[lang]);
                        else wrap.find('.collection_book_author').html("");

                        if (d.title && d.title[lang]) wrap.find('.collection_book_title').html(d.title[lang]);
                        else wrap.find('.collection_book_title').html("");

                        if (d.edition && d.edition.publisher) {
                            wrap.find('.collection_book_publisher').html(d.edition.publisher + " " + d.edition.year);
                        } else wrap.find('.collection_book_publisher').html("");

                        if (d.description && d.description[lang]) wrap.find('.collection_book_description').html(d.description[lang]);
                        else wrap.find('.collection_book_description').html("");

                        if (d.quote && d.quote[lang])
                            wrap.find('.collection_book_cite').html(d.quote[lang]).show();
                        else wrap.find('.collection_book_cite').hide();

                        if (d.review && d.review[lang]) wrap.find('.collection_book_review').html(d.review[lang]);
                        else wrap.find('.collection_book_review').html("");

                        if (d.lang && d.lang[lang]) wrap.find('.collection_book_lang').html(d.lang[lang]);
                        else wrap.find('.collection_book_lang').html("");

                        if (d.location && d.location[lang]) wrap.find('.collection_book_location').html(d.location[lang]);
                        else wrap.find('.collection_book_location').html("");

                        if (d.bibliog_desc && d.bibliog_desc[lang]) wrap.find('.collection_book_bibliog_desc').html(d.bibliog_desc[lang]);
                        else wrap.find('.collection_book_bibliog_desc').html("");

                        if (d.availInLib)  {
                            wrap.find('.collection_book_readinghall').show();
                            wrap.find('.popup_book_availability').text('Только в библиотеке');
                        }
                        else {
                            wrap.find('.collection_book_readinghall').hide();
                            wrap.find('.popup_book_availability').text('Доступно на дом');
                        }

                        if (d.lang && d.lang[lang]) {
                            wrap.find('.collection_book_label').html(d.lang[lang]).show();
                            wrap.addClass('with_label');
                        } else {
                            wrap.find('.collection_book_label').html("").hide();
                            wrap.removeClass('with_label');
                        }

                        if (d.location && d.location[lang]) {
                            wrap.find('.collection_book_place').html(d.location[lang]);
                            wrap.find('.collection_book_place_wrapper').show();
                        } else wrap.find('.collection_book_place_wrapper').hide();

                        if (d.documents && d.documents.length) {
                            $('.collection_book_download').attr('href', '/attachments/' + d.documents[0].attachment.original.path).show();
                        } else $('.collection_book_download').hide();

                        if (d.catalogueLink && d.catalogueLink.length) {
                            $('.collection_book_link').attr('href', d.catalogueLink).show();
                            wrap.find('.collection_book_catalogue_link').attr('href', d.catalogueLink).css('pointer-events', 'auto');
                        } else {
                            $('.collection_book_link').hide();
                            wrap.find('.collection_book_catalogue_link').css('pointer-events', 'none');
                        }

                        if (d.topImage) {
                            wrap.find('.popup_book_image').attr('src', '/attachments/' + d.topImage.attachment.preview.path).on('load', function() {
                                $(this).css('visibility', 'visible');
                            });
                        }
                    }
                }
            })
        } else {
            $('#popup_wrapper').show();
        }
        el.show();
        $('header, nav, main, #footer').css('filter', 'blur(5px)');
        $('body').css('overflow', 'hidden');
    }).on('click', '#popup_wrapper, .popup_close, .mobile_close', function(e) {
        if ($(this).hasClass('popup_close') || $(this).hasClass('mobile_close') || $(e.target).attr('id') == 'popup_wrapper') {
            $('.popup_box').hide();
            $('#popup_wrapper').hide();
            $('header, nav, main, #footer').css('filter', 'blur(0)');
            $('body').css('overflow', 'auto');
        }
    }).on('change', '.search-page__filters input', function() {
        var sel = '.search_' + $(this).val();
        if (this.checked) {
            $(sel).show();
        } else {
            $(sel).hide();
        }

        var hash = [];
        $('.search-page__filters input').each(function(i,e) {
            if (!e.checked) hash.push($(e).val());
        });
        window.location.hash = hash.join(',');
    }).on('click', '.open_place_popup', function(e) {
        e.preventDefault();
        $('.popup_box').hide();
        $('#popup_wrapper').show();
        $('#popup_map').show();
    }).on('click', '.btn_event_register', function(e) {
        e.preventDefault();
        $('.popup_box').hide();
        $('#popup_wrapper').show();
        $('#popup_event').show();
    })/*.on('click', '.become_icon', function(e) {
        e.preventDefault();
        $('.popup_box').hide();
        $('#popup_wrapper, #popup_reader').show();
        $('header, nav, main, #footer').css('filter', 'blur(5px)');
    })*/.on('click', '.index_search_form img', function() {
        $('.index_search_select').toggleClass('active');
    }).on('click', '.index_search_option', function() {
        var text = $(this).attr('data-text');
        var act = $(this).attr('data-action');
        $('#index_search').attr('placeholder', text);
        $('#index_search_form').attr('action', act);
        $('.index_search_select').removeClass('active');
    }).on('click', '#pageNext, #pagePrev, .paginator_links .avaible', function() {
        var elem = $('.switcher_wrapper').length ? $('.switcher_wrapper') : $('.filters_wrapper');
        var offs = elem.offset()['top'] - $('#fixed_menu').height();
        window.scrollTo(0, offs);
    }).on('click', '.event_free_enter', function(e) {
        e.preventDefault();
        $('.popup_box').hide();
        $('#popup_wrapper').show();
        $('#popup_enter_free').show();
    }).on('click', '.remove_date', function() {
        $(this).siblings('input').val('').trigger('change');
    }).on('click', '.inner-event-type a', function(e) {
        //e.preventDefault();
        var id = $(this).attr('href');
        var sel = $('#filter_category').length ? $('#filter_category') : $('#filter_department');
        console.log(sel);
        sel.val(id).trigger('change').selectmenu('refresh');
    }).on('click', '.event-type a', function(e) {
        e.preventDefault();
        var id = $(this).attr('href');
        var sel = $('#filter_category').length ? $('#filter_category') : $('#filter_department');
        console.log(sel);
        sel.val(id).trigger('change').selectmenu('refresh');
    }).on('click', 'a.event-type', function(e) {
        e.preventDefault();
        var id = $(this).attr('href');
        var sel = $('#filter_category').length ? $('#filter_category') : $('#filter_department');
        console.log(sel);
        sel.val(id).trigger('change').selectmenu('refresh');
    }).on('change', '#filter_category', function() {
        var v = $(this).val();
        if (v)
            window.location.hash = JSON.stringify({category: $(this).val()});
        else
            window.location.hash = '';
    }).on('change', '#filter_department', function() {
        var v = $(this).val();
        if (v)
            window.location.hash = JSON.stringify({news: $(this).val()});
        else
            window.location.hash = '';
    }).on('change', '#collection_filter', function() {
        var v = $(this).val();
        if (v)
            window.location.hash = JSON.stringify({collection: $(this).val()});
        else
            window.location.hash = '';

    })
    /*.on('click', '.catalogue_search_form img', function() {
            $('.catalogue_search').show();
            $('.vf_search_select').show();
    })*/.on('click', '.events-filters__switch', function() {
        $('.events-filters__hidden').toggle();

    }).on('click', '.breadcrumb_current', function() {
        $(this).find('.breadcrumb_links').toggle();
    }).on('click', '.index_search_option', function() {
        $('#index_search').attr({
            'data-filters': $(this).data('action'),
            'placeholder': $(this).data('text')
        });
        $('.index_search_select').hide();

    })//TODO: Переключение баннера
        .on('click', '.index_show_left', function() {
            var $container = $('#index_banners_inner');
            var current_pos = $container.attr('data-x') ? parseInt($container.attr('data-x')) : 0;
            var shift = $('#index_banners_wrapper').width();
            clearInterval(indexShowInterval);
            console.log(current_pos)
            if (current_pos > 0) {
                if (current_pos - shift < 0) current_pos = shift;
                $container.css('transform', 'translateX(-' + (current_pos - shift) + 'px)')
                $container.attr('data-x', current_pos - shift);
                $('.learning_show_pin.active').removeClass('active').prev().addClass('active');
            }
        }).on('click', '.index_show_right', function() {
        var $container = $('#index_banners_inner');
        var current_pos = $container.attr('data-x') ? parseInt($container.attr('data-x')) : 0;
        var shift = $('#index_banners_wrapper').width();
        var max = ($container.find('a').length - 1) * shift;
        clearInterval(indexShowInterval);
        console.log(max)
        if (current_pos < max) {
            if (current_pos + shift > max) current_pos = max - shift;
            $container.css('transform', 'translateX(-' + (current_pos + shift) + 'px)')
            $container.attr('data-x', current_pos + shift);
            $('.learning_show_pin.active').removeClass('active').next().addClass('active');
        }
    })
    /*.on('click', '.banners_show_up', function() {
        var $container = $('.banners_show_inner');
        var current_pos = $container.attr('data-y') ? parseInt($container.attr('data-y')) : 0;
        var shift = 200;
        if (current_pos > 0) {
            if (current_pos - shift < 0) current_pos = shift;
            $container.css('transform', 'translateY(-' + (current_pos - shift) + 'px)')
            $container.attr('data-y', current_pos - shift);
        }
    }).on('click', '.banners_show_down', function() {
        var $container = $('.banners_show_inner');
        var current_pos = $container.attr('data-y') ? parseInt($container.attr('data-y')) : 0;
        var shift = 200;
        var max = ($container.find('.column_banner').length - 3) * shift;
        if (current_pos < max) {
            if (current_pos + shift > max) current_pos = max - shift;
            $container.css('transform', 'translateY(-' + (current_pos + shift) + 'px)')
            $container.attr('data-y', current_pos + shift);
        }
    })*/

    if ($('.search-page__filters input').length && window.location.hash) {
        var disabledCats = window.location.hash.substr(1).split(',');
        for (var i = 0; i < disabledCats.length; i++) {
            $('.search-page__filters').find('input[value="' + disabledCats[i] + '"]').prop('checked', false).trigger('change').parent().removeClass('checked');
        }
    }




    /*
     ===================== News subscription  =====================
     */

    var options= {
        success: processSubscribe,
        error: errorSubscribe,
        beforeSubmit: beforeSubscrive

    };
    function processSubscribe(d) {
        console.log(d);
        $('#popup_post_result .popup_text a').text($('#popup_subscribe input[name="email"]').val());
        $('#popup_post_result .popup_text').show();
        $('#popup_post_result .popup_error_text').hide();
        $('.popup_box').hide();
        $('#popup_post_result').show();
    }
    function errorSubscribe(d) {
        if (d.status==200)
            return processSubscribe(d);
        console.log(d);
        $('#popup_post_result .popup_text').hide();
        $('#popup_post_result .popup_error_text').show();
        $('.popup_box').hide();
        $('#popup_post_result').show();

    }
    function beforeSubscrive() {
        if ($('.subscribe_email').val() == '') {
            $('.subscribe_email').addClass('error');
            e.preventDefault();
            return false;
        }
    }
    var form = $('#subscribe_form');
    if (form.ajaxForm)
        form.ajaxForm(options);
    /*
     ===================== end of News subscription  =================
     */

    /*
     ===================== EVENT REGISTRATION  =====================
     */

    var options= {
        success: processReg,
        error: errorReg,
        beforeSubmit: beforeReg

    };
    function processReg(d) {
        console.log(d);
        $('#popup_post_result .popup_text a').text($('#popup_event input[name="email"]').val());
        $('#popup_post_result .popup_text').show();
        $('#popup_post_result .popup_error_text').hide();
        $('.popup_box').hide();
        $('#popup_post_result').show();
    }
    function errorReg(d) {
        if (d.status==200)
            return processReg(d);
        console.log(d);
        $('#popup_post_result .popup_text').hide();
        $('#popup_post_result .popup_error_text').show();
        $('.popup_box').hide();
        $('#popup_post_result').show();
    }
    function beforeReg() {
        var err = false;
        $('#popup_event .req input').each(function(i,e) {
            if ($(e).val() == '') {
                $(e).addClass('error');
                err = true;
            } else {
                $(e).removeClass('error');
            }
        });
        if (err) {
            e.preventDefault();
            return false;
        }
    }
    var form = $('#event_form');
    if (form.ajaxForm)
        form.ajaxForm(options);

    if ($('.about_block').length) {
        $('.about_block span').css('visibility', 'hidden');
        setTimeout(function() {
            $('.about_block span').css('visibility', 'visible');
        }, 2000);
    }
    /*
     ===================== EVENT REGISTRATION =================
     */

    /*
     ===================== NEW NEWS/EVENTS LIST =================
     */
    moment.tz.setDefault('Europe/Moscow');
    if ($('#block_types').length) {
        var lang = $('#current-language').data('lang');
        moment.locale(lang);
        var BLOCKS = {
            event : {
                url: '/' + lang + '/eventlist',
                container: '.events-by-date',
                template: $('#event_template').html()
            },
            exhibitions : {
                url: '/' + lang + '/eventlist',
                container: '#exhibitions_carousel',
                template: $('#exhibition_template').html(),
                parameters: {
                    s: 0,
                    l: 50,
                    fullRange: true,
                    category: "56613ec7f3e055e01a3facb5",
                    from: moment().startOf('day').toISOString(),
                    to: moment().endOf('day').toISOString()
                }
            },
            department: {
                url: '/' + lang + '/eventlist',
                container: '.events-by-date',
                template: $('#event_template').html()
            },
            recommend: {
                url: '/ajax/events_suggested',
                container: '.recommend_inner',
                template: $('#recommend_block').html()
            },
            news: {
                url: '/' + lang + '/newslist',
                container: '.news_wrapper',
                template: $('#event_template').html()
            }
        };
        var this_blocks = $('#block_types').val().split(' ');
        function blocks_success(block, append, data) {
            var d = data.data ? data.data : data;
            var cur_block = BLOCKS[block];
            if (d.length) {
                switch (block) {
                    case 'event':
                        var d_by_date = {};
                        $.each(d, function (i, e) {
                            if (e.schedule) {
                                var date = moment(e.schedule.from).format('DD MMMM YYYY, dddd');
                                if (typeof d_by_date[date] == 'object') {
                                    d_by_date[date].push(e);
                                } else {
                                    d_by_date[date] = [e];
                                }
                            }
                        });

                        var d_html = '';
                        var d_tmpl = $('#date_template').html();
                        var longTerm =  $('#long_term_events_template').html();
                        var longTermEvent =  $('#long_term_events_item_template').html();
                        $.each(d_by_date, function (i, e) {
                            var html = '';
                            var html2 = '';
                            var day, year, weekday;
                            //TODO получать случайное число от 0 до e.length. Это будет индекс места, в которое надо вставить карточку с длительными
                            //TODO еще до перебора всех событий формировать карточку с длительными. Т.е. просто написать еще один $.each(e, function (j, o) и в него перернести html2 += longTermEvent.replace
                            //html2 лучше переименовать, а то не понятно что это значит, легко запутаться

                            $.each(e, function (j, o) {
                                var pushkin = o.isPushkin ? '<img class="event-card" src="/images/svg/pushkin_card.svg">' : '';
                                var label, cycle = false;
                                if (o.tag && !o.cycle) label = '<div class="event-tag">' + o.tag + '</div>';
                                if (o.cycle) cycle = '<a class="event-cycle" data-cycle="' + o.cycle._id + '" href=\'/' + lang + '/events#{\"cycle\":\"' + o.cycle._id + '\"}\'>' + o.cycle.title + '</a>';
                                var lead = (o.lead && o.lead.ru) ? '<div class="events-item__lead-text">' + o.lead.ru + '</div>' : '';
                                var time = moment(o.schedule.from).format('HH:mm')+"—"+moment(o.schedule.to).format('HH:mm');
                                day = moment(o.schedule.from).format('DD MMMM');
                                year = '&nbsp;' + '&nbsp;' + moment(o.schedule.from).format('YYYY');
                                weekday = moment(o.schedule.from).format('dddd');

                                if (o.longTerm != 'long' && o.category._id != '56613ec7f3e055e01a3facb5') {
                                    if (!o.blockWithPicture) {
                                        html += cur_block.template
                                            .replace(/%pushkin%/g, pushkin)
                                            //.replace(/%image%/g, o.topImage ? o.topImage.previewURL : '').replace(/%flag%/g, flag)
                                            .replace(/%type%/g, o.category ? o.category._id : '')
                                            .replace(/%type_name%/g, o.category ? o.category.title : '')
                                            //.replace(/%tag%/g, o.subCategory ? ('&ensp;' + o.subCategory) : '')
                                            .replace(/%title%/g, o.title).replace(/%time%/g, time)
                                            .replace(/%lead%/g, lead)
                                            .replace(/%place%/g, o.place ? '<div class="events-item__place">' + o.place.title + '</div>' : '<div class="events-item__place online-event">Онлайн-мероприятие</div>')
                                            //.replace(/%views%/g, o.views)
                                            .replace(/%url%/g, o.url).replace(/%label%/g, label || '')
                                            .replace(/%cycle%/g, cycle || '');
                                    } else {
                                        html += $('#event_with_picture_template').html().replace(/%image%/g, o.topImage ? o.topImage.squareURL : '')
                                            .replace(/%pushkin%/g, pushkin)
                                            .replace(/%type%/g, o.category ? o.category._id : '').replace(/%type_name%/g, o.category ? o.category.title : '')
                                            .replace(/%title%/g, o.title).replace(/%time%/g, time)
                                            .replace(/%place%/g, o.place ? '<div class="events-item__place">' + o.place.title + '</div>' : '<div class="events-item__place online-event">Онлайн-мероприятие</div>')
                                            //.replace(/%tag%/g, o.tag)
                                            .replace(/%url%/g, o.url)
                                            .replace(/%label%/g, label || '')
                                            .replace(/%cycle%/g, cycle || '');
                                    }
                                } else {
                                        html2 += longTermEvent.replace(/%type%/g, o.category ? o.category._id : '').replace(/%type_name%/g, o.category ? o.category.title : '').replace(/%url%/g, o.url).replace(/%title%/g, o.title);
                                    }
                                //TODO else отсюда, соответственно, уедет
                                //вместо него будет проверка if (html2 && j == <тот самый случайный индекс>) и если true, то добавляем к html кода нашей карточки, т.е. переносим сюда код, который чуть ниже: html += longTerm.replace
                            });
                            if (html2) {
                                html += longTerm.replace(/%html%/g, html2);
                            }
                            d_html += d_tmpl.replace(/%day%/g, day).replace(/%year%/g, year).replace(/%weekday%/g, weekday).replace(/%html%/g, html);

                        });

                        if (append)
                            $(cur_block.container).append(d_html);
                        else
                            $(cur_block.container).html(d_html);

                        longTermProcessing ();
                        break;
                    case 'recommend':
                        var html = '';
                        var ids = [];
                        var show;
                        show = d.filter(function(e) {
                            if (ids.indexOf(e._id) < 0) {
                                ids.push(e._id);
                                return true;
                            } else return false;
                        });
                        // var num;
                        // if (window.innerWidth > 1160)
                        //     num = show.length % 4;
                        // else
                        //     num = show.length % 3;
                        // var removed = show.splice(show.length-num, num);
                        $.each(show, function (i, e) {
                            var date = '';
                            if (e.schedule.length>1) {
                                var B = [];
                                e.schedule.forEach(function(date) {
                                    B.push(new Date(date.from)-0)
                                });
                                var min = Math.min.apply(null, B);
                                var max = Math.max.apply(null, B);
                                date = moment(min).format('D MMMM')+"—"+moment(max).format('D MMMM')
                            } else if (e.schedule && e.schedule.length)
                                date = moment(e.schedule[0].from).format('D MMMM');

                            html += cur_block.template.replace(/%image%/g, "/attachments/"+e.topImage.attachment.preview.defaultUrl)
                                .replace(/%type%/g, '/'+lang+'/events#{category:'+e.category._id+'}').replace(/%type_name%/g, e.category.title[lang])
                                .replace(/%date%/g, date)
                                .replace(/%title%/g, e.title[lang]).replace(/%url%/g, '/'+lang+'/event/'+e.slug);
                        });
                        if (show.length < 5) $('.recommend_arrow_right').addClass('disabled');
                        $(cur_block.container).html(html);
                        break;
                    case 'news':
                        var html = '';
                        $.each(d, function (i, e) {
                            var pushkin = e.isPushkin ? '<img class="event-card" src="/images/svg/pushkin_card.svg">' : '';
                            var flag = (e.department && e.department.logo) ? '<img class="event-flag" src="' + e.department.logo + '">' : '';
                            var date = moment(e.date).format('D MMMM YYYY');
                            var tag = e.tag ? '<div class="event-tag">' + e.tag + '</div>' : '';
                            var centenary = e.centenary ? '<div class="centenary_tag">Нам &mdash; 100!</div>' : '';
                            var donbass = e.donbass ? '<div class="donbass_tag">БиблиоДонбасс</div>' : '';
                            var localizedTypes = {en: {report:'report', news:'news',press:'press',review:'review',collection:'collection',interview:'interview',read:'read',academy:'from Academy life',international:'international activities'}, ru: {report:'репортаж', news:'новость',press:'пресса о нас',review:'рецензия',collection:'подборка',interview:'интервью',read:'чтение',academy:'Из жизни Академии',international:'Международная деятельность'}}
                            var type;
                            switch (e.type) {
                                case 'report':
                                    type = [localizedTypes[lang].report];
                                    break;
                                case 'news':
                                    type = [localizedTypes[lang].news];
                                    break;
                                case 'press':
                                    type = [localizedTypes[lang].press];
                                    break;
                                case 'academy':
                                    type = [localizedTypes[lang].academy];
                                    break;
                                case 'international':
                                    type = [localizedTypes[lang].international];
                                    break;
                                case 'review':
                                    type = [localizedTypes[lang].review];
                                    break;
                                case 'collection':
                                    type = [localizedTypes[lang].collection];
                                    break;
                                case 'interview':
                                    type = [localizedTypes[lang].interview];
                                    break;
                                case 'read':
                                    type = [localizedTypes[lang].read];
                                    break;
                            }
                            var image = '';
                            if (e.topImage && e.topImage.middleURL) {
                                image = $('#events_image').html().replace(/%image%/g, e.topImage.middleURL).replace(/%pushkin%/g, pushkin).replace(/%url%/g, e.url);
                            }
                            html += cur_block.template.replace(/%image_block%/g, image).replace(/%url%/g, e.url).replace(/%flag%/g, flag)
                                .replace(/%type%/g, e.type).replace(/%type_name%/g, type).replace(/%centenary%/g, centenary).replace(/%donbass%/g, donbass)
                                .replace(/%date%/g, date).replace(/%tag%/g, tag).replace(/%title%/g, e.title).replace(/%title%/g, e.title);
                        });
                        if (append)
                            $(cur_block.container).append(html);
                        else
                            $(cur_block.container).html(html);
                        break;
                    case 'exhibitions':
                        var html = '';
                        console.log($(cur_block.container).html());
                        $.each(d, function (i, e) {
                            if (e.schedule.length>1) {
                                var B = [];
                                e.schedule.forEach(function(date) {
                                    B.push(new Date(date.from)-0)
                                });
                                var min = Math.min.apply(null, B);
                                var max = Math.max.apply(null, B);
                                var date = moment(min).format('D MMMM')+"—"+moment(max).format('D MMMM')
                            } else
                                var date = moment(e.schedule.from).format('D MMMM') + ((moment(e.schedule.from).startOf('day').toISOString() != moment(e.schedule.to).startOf('day').toISOString()) ? '&nbsp;&mdash;&nbsp;' + moment(e.schedule.to).format('D MMMM') : '');

                            html += cur_block.template
                                .replace(/%image%/g, e.topImage.previewURL)
                                .replace(/%date%/g, date)
                                .replace(/%title%/g, e.title)
                                .replace(/%url%/g, '/'+lang+'/event/'+e.slug)
                                .replace(/%image_type%/g, (i%2 == 1) ? 'image_block' : '');
                        });
                        $(cur_block.container).html(html);
                        var carouselSettings = {
                            nav: false,
                            margin: 30,
                            responsive:{
                                0:{
                                    items: 1,
                                    stagePadding: 0
                                },
                                750:{
                                    items: 2
                                },
                                1000:{
                                    items: 3
                                },
                                1200: {
                                    items: 4
                                },
                                1400: {
                                    items: 4
                                }
                            }
                        };
                        if (d.length > 4) {
                            carouselSettings.loop = true;
                            carouselSettings.stagePadding = 105;
                            carouselSettings.responsive[1200].stagePadding = 40;
                            carouselSettings.responsive[1000].stagePadding = 40;
                            carouselSettings.nav = true;
                        } else {
                            $('#exhibitions_block').addClass('content-wrap');
                        }
                        console.log($('.owl-carousel'), $('.owl-carousel').hasClass('.owl-loaded'));
                        $('.owl-carousel').owlCarousel(carouselSettings);
                        break;
                    default:
                        console.error('Неизвестный тип блока');
                        break;
                }
                $('#nothing_found').hide();
                if (data.l + data.s >= data.total) {
                    $('.show-more').hide();
                } else $('.show-more').show();
            } else {
                $(cur_block.container).html('');
                $('#nothing_found').show();
                $('.show-more').hide();
            }
        }

        for (var b = 0; b < this_blocks.length; b++) {
            ajax_blocks(this_blocks[b]);
        }
        function ajax_blocks(block, append) {
            var filters = {};
            if ($('#block_types').data('filters')) {
                $.extend(filters, $('#block_types').data('filters'));
            }
            if (block != 'recommend' && block != 'exhibitions') {
                $('.events-filters__wrapper').find('select, input').each(function(i,e) {
                    var name = $(e).attr('name');
                    var val = $(e).val();
                    if ($(e).attr('type') == 'checkbox' && e.checked) filters[name] = true;
                    if (name == 'from') {
                        if (block == 'news') {
                            if (val != '')
                                val = moment(val, 'DD.MM.YYYY').add(1, 'days').toISOString();
                            filters.o = '{"date":-1}';
                            name = 'to';
                        } else if (val != '') {
                            val = moment(val, 'DD.MM.YYYY').toISOString();
                        }
                    }
                    if ($(e).attr('type') != 'checkbox' && val != '') filters[name] = val;
                });
                var btn = $('.show-more');
                filters.s = parseInt(btn.data('s'));
                filters.l = parseInt(btn.data('l'));
                btn.data('s', filters.s + filters.l);
            }
            if (BLOCKS[block].parameters) {
                filters = BLOCKS[block].parameters;
            }
            console.log(filters);
            $.ajax({
                type: 'get',
                data: filters,
                url: BLOCKS[block].url,
                success: blocks_success.bind(this, block, append)
            });
        }

        var f_block = false;
        $('.events-filters__wrapper').find('input, select').on('change', function() {
            if (f_block) return;
            f_block = true;
            $('.show-more').data('s', 0);
            var block = 'event';
            if ($('.news_wrapper').length) block = 'news';
            ajax_blocks(block);
            setTimeout(function() {
                f_block = false;
            }, 500);
        });

        $('body').on('click', '.event-cycle', function(e) {
            $('.show-more').data('s', 0);
            var block = 'event';
            $('#cycle_input').val($(this).data('cycle'));
            $('#current_cycle').addClass('active').find('span').text($(this).text());
            ajax_blocks(block);
            window.scrollTo(0, 0);
        });

        $('.remove_cycle').on('click', function() {
            $('.show-more').data('s', 0);
            var block = 'event';
            $('#cycle_input').val('');
            $('#current_cycle').removeClass('active');
            ajax_blocks(block);
            window.scrollTo(0, 0);
        });

        $('.show-more').on('click', function() {
            ajax_blocks($(this).data('block'), true);
        });
    }

    if ($('.content-wrap').length) {
        $(window).on('scroll', function(ev) {
            var date = false;
            $('.content-wrap .events_date_self').each(function(i,e) {
                if (window.scrollY + 50 > $(e).offset()['top']) {
                    date = $(e).text();
                }
            });
            if (date) {
                $('#fixed_date_wrapper').addClass('active');
                $('#current_date').text(date);
            } else {
                $('#fixed_date_wrapper').removeClass('active');
                $('#current_date').text('');
            }
        });
    }

    /*if ($('.content_big.events_wrapper').length) {
        $(window).on('scroll', function(ev) {
            var date = false;
            $('.events_wrapper .events_date_self').each(function(i,e) {
                if (window.scrollY + 50 > $(e).offset()['top']) {
                    date = $(e).text();
                }
            });
            if (date) {
                $('#fixed_date_wrapper').addClass('active');
                $('#current_date').text(date);
            } else {
                $('#fixed_date_wrapper').removeClass('active');
                $('#current_date').text('');
            }
        });
    }*/

    $('body').on('click', '.js-arrow-down', function() {
        var $container = $(this).parents().eq(1).find('.long-term-events__inner');
        var current_pos = $container.attr('data-y') ? parseInt($container.attr('data-y')) : 0;
        var shift = 160;
        if (current_pos > 0) {
            if (current_pos - shift < 0) current_pos = shift;
            $container.css('transform', 'translateY(-' + (current_pos - shift) + 'px)')
            $container.attr('data-y', current_pos - shift);
        }
    }).on('click', '.js-arrow-up', function() {
        var $container = $(this).parents().eq(1).find('.long-term-events__inner');
        var current_pos = $container.attr('data-y') ? parseInt($container.attr('data-y')) : 0;
        var shift = 160;
        var max = ($container.find('.long-term-events__item').length - 2) * shift;
        if (current_pos < max) {
            if (current_pos + shift > max) current_pos = max - shift;
            $container.css('transform', 'translateY(-' + (current_pos + shift) + 'px)')
            $container.attr('data-y', current_pos + shift);
        }
    });


    /*
     ===================== NEW NEWS/EVENTS LIST =================
     */

    if ($('#popup_plan').length) {
        var months = {
            'en': ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
            'ru': ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь']
        };
        $('#popup_plan .popup_text').hide();
        var html = '';
        var m_html = '';
        var d_html = '';
        var monthTemplate = '<div class="popup_days_month"><div class="popup_month_name">%month% %year%</div>%days_html%</div>';
        var dayTemplate = '<div class="popup_days_day"> \
                        <span class="addtocalendar atc-style-blue"> \
                            <a class="atcb-link">%day%</a> \
                            <var class="atc_event"> \
                                <var class="atc_date_start">%atc_date_start%</var> \
                                <var class="atc_date_end">%atc_date_end%</var> \
                                <var class="atc_timezone">Europe/Moscow</var> \
                                <var class="atc_title">%atc_title%</var> \
                                <var class="atc_description">%atc_description%</var> \
                                <var class="atc_location">Moscow</var> \
                                <var class="atc_organizer">%atc_organizer%</var> \
                                <var class="atc_organizer_email">%atc_organizer_email%</var> \
                            </var> \
                            <small>%time%</small> \
                        </span> \
                   </div>';
        var days_found = false;
        var lang = window.location.pathname.split("/")[1] || "ru";
        var email = (window.event_obj && window.event_obj.responsible && window.event_obj.responsible.profile)?window.event_obj.responsible.profile.email:window.event_obj.department.email;

        console.log(email);
        email = email || "";

        // console.log("DATA2:", local_data2);
        var calendar = {};
        $('.plan_day_wrapper').each(function(i,e) {
            var dateFrom = moment(JSON.parse($(e).text()).from, 'YYYY-MM-DD HH:mm:ss zzZZ');
            var dateTo = moment(JSON.parse($(e).text()).to, 'YYYY-MM-DD HH:mm:ss zzZZ');
            if (dateFrom.diff(moment(), 'days') >= 0) {
                days_found = true;
                var month = months[window.location.pathname.split('/')[1]][dateFrom.month()];
                var year = dateFrom.format('YYYY');
                var index = month + ' ' + year;
                if (!calendar[index]) calendar[index] = [];
                calendar[index].push({
                    from: dateFrom,
                    to: dateTo
                });
            }
        });

        for (monthYear in calendar) {
            var month = calendar[monthYear];
            var dateFrom, dateTo;
            d_html = '';
            for (var i = 0; i < month.length; i++) {
                dateFrom = month[i].from;
                dateTo = month[i].to;
                d_html += dayTemplate.replace(/%day%/g, dateFrom.format('D'))
                    .replace(/%time%/g, dateFrom.format('HH:mm')+"—"+dateTo.format('HH:mm'))
                    .replace(/%atc_date_start%/g, dateFrom.format("YYYY-MM-DD HH:mm"))
                    .replace(/%atc_date_end%/g, dateTo.format("YYYY-MM-DD HH:mm"))
                    .replace(/%atc_title%/g, window.event_obj.category.title[lang])
                    .replace(/%atc_description%/g, window.event_obj.title[lang])
                    .replace(/%atc_organizer%/g, window.event_obj.responsible && window.event_obj.responsible.profile?window.event_obj.responsible.profile.name:window.event_obj.department.title[lang])
                    .replace(/%atc_organizer_email%/g, email);
            }
            m_html += monthTemplate.replace(/%month%/g, monthYear).replace(/%year%/g, '').replace(/%days_html%/g, d_html);
        }

        $('#popup_plan .popup_text').html(m_html).show();
        if (days_found) {
            $('.open_popup_plan').show();
        }
    }

    function longTermProcessing() {
        console.log();
        $('.test-wrapper').find('.long-term-events.unexamined').each(function(i,e){
            var q = $(e).find('.long-term-events__item').length;
            var arr = $(e).find('.long-term-events__arrows');
            if (q < 3) {
                $(arr).hide();
            }
            $(e).removeClass('unexamined');
        })
    }


    var curLang = $("#current-language").data("lang");

    function cutLongread(el,size) {
        $(el).each(function(){
            newsContent= $(this),
                newsText = newsContent.text();
            if(newsText.length > size) newsContent.text(newsText.slice(0, size) + '...');
        });
    }
    cutLongread('.top-entity__lead',180);

    // Активный пункт меню
    var CurrentPage = window.location.pathname;
    $('ul.main-menu li a').each(function(i,e) {
        if (CurrentPage.indexOf('/ru/event') + 1) {
            $('ul.main-menu li a[href="/ru/events"]').parent().addClass('active')
        }
        if (CurrentPage.indexOf('/ru/news') + 1) {
            $('ul.main-menu[href="/ru/novosti-biblioteki"]').parent().addClass('active')
        } else {
            if (CurrentPage.indexOf($(e).attr('href')) + 1) {
                $(e).parent().addClass('active');
            }
        }
    });

    // Логотип на закрепленном меню
    $(window).on('scroll', function(e) {
        $('a.fixed-logo').toggle(window.pageYOffset > $('nav').height());
    });

    //
    $("body").on("click", "button.dropdown-toggle-js", function() {
        $(".dropdown-menu").toggle();
    });

    $("body").on('click', 'button.nav-mobile-toggle-js', function() {
        $('.nav-mobile').toggle();
    });

    if ($("window").width() > 768) {
        $(".dropdown-menu")
    }

    // Переключение языка
    $('#switch-language').on('click', function() {
        var url = window.location.toString();
        var url_lang = url.split('/')[3];

        switch (url_lang) {
            case 'en':
                window.location = url.replace('/en', '/ru');
                break;
            case 'ru':
                window.location = url.replace('/ru', '/en');
                break;
            default:
                var url_lang = '';
                curLang = (curLang === 'en') ? curLang = 'ru' : curLang = 'en';
                window.location = url + curLang
        }
    });

    // Рабочие часы
    (function setWorkdayEnd() {
        var localizedStrings = {en: {work: 'Today we work until ', closed: 'Today we\'re closed'}, ru: {work: 'Сегодня мы работаем до ', closed: 'Сегодня мы не работаем'}};
        var curDate = moment().format('DD.MM.YYYY');
        var time = [[localizedStrings[curLang].work]+'19:00', [localizedStrings[curLang].work]+'21:00', [localizedStrings[curLang].work]+'21:00', [localizedStrings[curLang].work]+'21:00', [localizedStrings[curLang].work]+'21:00', [localizedStrings[curLang].work]+'21:00', [localizedStrings[curLang].work]+'19:00'];
        var holidays = [];

        ($.inArray(curDate, holidays) != -1) ? $("a.working-hours").text([localizedStrings[curLang].closed]).show() : $("a.working-hours").text(time[moment().day()]).show();
    })();

    // User ID
    $.ajax({
        url: "/lk/user",
        success: function() {
            $('#user-id').text('Личный кабинет');
        },
        error: function() {
            console.error(arguments);
            $('#user-id').text('Войти');
        }
    });

    $("body").on("click", "#become-member", function(e) {
        e.preventDefault();
        $(".popup_box").hide();
        $("#popup_wrapper, #popup_reader").show();
        $("header, nav, main, #footer").css("filter", "blur(5px)");
    })

    if (window.location.hash === "#become-member") {
        $(".popup_box").hide();
        $("#popup_wrapper, #popup_reader").show();
        $("header, nav, main, #footer").css("filter", "blur(5px)");
    }


    // Top Banner
    if (window.banner_obj)  {
        var closed = localStorage.getItem('closed');
        var lastModified = (window.banner_obj && window.banner_obj[0]) ? window.banner_obj[0].modified : null;
        var lastShown = localStorage.getItem('lastShown');

        if(lastModified != lastShown) {
            localStorage.removeItem('closed');
            $(".top-banner").delay(500).fadeIn();
        };

        if(!closed){
            $(".top-banner").delay(500).fadeIn();
            localStorage.setItem("lastModified", lastModified);
            lastShown = localStorage.getItem("lastModified");
            localStorage.setItem("lastShown", lastShown);
        };

        $('.top-banner-close').click(function(e) {
            $('.top-banner').fadeOut();
            localStorage.setItem('closed', true);
            console.log(234)
        });

        console.warn('closed: ' + closed);
        console.warn('lastShown: ' + lastShown);
        console.warn('lastModified: ' + lastModified);
        console.warn(lastModified != lastShown);
    }

    // Accordion
    (function() {
        var titles = $('.accordion > dt');
        var contents = $('.accordion > dd');
        titles.on('click',function(){
            var title = $(this);
            contents.filter(':visible').slideUp(function(){
                $(this).prev('dt').removeClass('is-opened');
            });
            var content = title.next('dd');
            if (!content.is(':visible')) {
                content.slideDown(function(){title.addClass('is-opened')});
            }
        });
    })();

    // Запуск поиска нажатием на enter
    $("#index_search").keypress(function(e){
        var q = $(this).val()
        if (q.length && e.which==13) {
            $("#index_search_form").submit();
        }
    })

    // Рандомный плейсхолдер
    var changeSearchPlaceholder = function() {
        var placeholders = [
            'James Joyce',
            'Les Misérables',
            'The Great Gatsby',
            'Götz von Berlichingen',
            'Атлант расправил плечи',
            'Charlotte Bronte',
            'Antoine de Saint-Exupéry',
            'Françoise Sagan',
            'Доктор Живаго'
        ];
        $(".search-form input").attr('placeholder', placeholders[Math.floor(Math.random()*placeholders.length)]);
    };
    changeSearchPlaceholder();

    // Переключение области поиска
    $("body").on('click', 'a.search-switch', function(e) {
        e.preventDefault();

        var wrap = $('.search');
        var localizedStrings = {en: {website: 'Search  <b>Website</b>', catalogue: 'Search <b>Catalogue</b>'}, ru: {website: 'Поиск <b>по сайту</b>', catalogue: 'Поиск <b>по каталогу</b>'}}

        if (wrap.hasClass("catalogue")) {
            wrap.removeClass("catalogue");
            $(this).html([localizedStrings[curLang].website]);
            $('.search-form input').attr('placeholder', 'Поиск по сайту');
        } else {
            wrap.addClass("catalogue");
            $(this).html([localizedStrings[curLang].catalogue]);
            $('.search-form input').attr('placeholder', 'Поиск по каталогу');
        }
    });

    $('body').on('click', 'ul.search-select li', function() {
        $('.search-form input').attr({
            'data-filters': $(this).data('action'),
            'placeholder': $(this).data('text')
        });
        $('ul.search-select').hide();

    })

    if (window.location.href.indexOf('search?q=') > 0) {
        $('a.search-switch').click();
    }

    $('button.search-options-toggle-js').on('click', function() {
        var wrap = $('.search');
        if (wrap.hasClass('catalogue')) {
            $(this).siblings('.search-select.site').hide();
            $(this).siblings('.search-select.catalogue').toggle();
        } else {
            $(this).siblings('.search-select.catalogue').hide();
            $(this).siblings('.search-select.site').toggle();
        }
    });

    //todo: исправить
    $('body').on('click', '*', function(e) {
        var t = $(e.target);
        t.parents(".search-form").length && "search" != t.attr("id") || $("ul.search-select").hide();
    });

    $(".search-form input").keypress(function(e){
        var q = $(this).val();
        if (q.length && e.which==13) {
            var wrap = $('.search');
            if (wrap.hasClass('catalogue')) {
                var search_url = 'http://catalog.libfl.ru/Search/Results?';
                switch ($(this).attr('data-filters')) {
                    case 'author':
                        search_url += 'sort=relevance&join=AND&lookfor0%5B%5D=' + encodeURI(q) + '&type0%5B%5D=Author&bool0%5B%5D=AND&illustration=-1&daterange%5B%5D=publishDate';
                        break;
                    case 'title':
                        search_url += 'sort=relevance&join=AND&lookfor0%5B%5D=' + encodeURI(q) + '&type0%5B%5D=Title&bool0%5B%5D=AND&illustration=-1&daterange%5B%5D=publishDate';
                        break;
                    case 'subject':
                        search_url += 'sort=relevance&join=AND&lookfor0%5B%5D=' + encodeURI(q) + '&type0%5B%5D=Subject&bool0%5B%5D=AND&illustration=-1&daterange%5B%5D=publishDate';
                        break;
                    case 'isbn':
                        search_url += 'sort=relevance&join=AND&lookfor0%5B%5D=' + encodeURI(q) + '&type0%5B%5D=ISN&bool0%5B%5D=AND&illustration=-1&daterange%5B%5D=publishDate';
                        break;
                    default:
                        search_url += 'lookfor=' + encodeURI(q) + '&type=AllFields';
                        break;
                }
                window.location = search_url;
            } else {
                var pp = window.location.pathname.split("/");
                var lang = pp[0] || pp[1] || "ru";
                var filters = '';
                if ($(this).attr('data-filters')) filters = '&f=' + $(this).attr('data-filters');
                window.location = "/" + lang + "/search?q=" + encodeURI(q) + filters;
            }
        }
    });



    if (window.location.search.indexOf('&f=') > 0) {
        var filter = window.location.search.substr(window.location.search.indexOf('&f=') + 3);
        console.log(filter);
        $('.search-form input').each(function(i,e) {
            if ($(e).val() != filter) $(e).parents('label').click();
        });
    }
});

/*$(window).on('scroll', function() {
    var wScroll = $(this).scrollTop();
    var introHeight = $('.index_news_wrapper').height();
    $('.news_right_image').css({
        'transform': 'translate(0px, ' + wScroll * 0.3 + 'px)'
    });
    $('.news_left_image').css({
        'transform': 'translate(0px, -' + wScroll * 0.3 + 'px)'
    });
});*/


$(window).load(function() {
    var CurrentPage = window.location.pathname;
    if (CurrentPage.indexOf('/ru/department') + 1) {

        $('.department__menu-anchor').each(function (i,e) {
            var a = $(e).attr('id');

            switch (a) {
                case 'department-services' :
                    $('#d-services').css('display', 'inline-block');
                    break;
                case 'department-about' :
                    $('#d-about').css('display', 'inline-block');
                    break;
                case 'department-books' :
                    $('#d-books').css('display', 'inline-block');
                    break;
                case 'department-history' :
                    $('#d-history').css('display', 'inline-block');
                    break;
                case 'department-contacts' :
                    $('#d-contacts').css('display', 'inline-block');
                    break;
            }

        });


    };

    if ($('.about_block').length) {
        $('.about_block span').css('visibility', 'hidden');
        $('.about_block').each(function(i,e) {
            var w = $(e).width();
            var iw = $(e).find('span').width();
            var fz = 24;
            while (iw > w) {
                fz -= 1;
                $(e).find('span').css('font-size', fz + 'px');
                iw = $(e).find('span').width();
            }
            $(e).find('span').css('visibility', 'visible');
        });
    }

    if ($('.event-info').length) {
        var eh = $('.event-info__title').height();
        var mh = 160;
        var fs = 28;
        while (eh > mh) {
            fs -= 1;
            $('.event-info__title').css('font-size', fs + 'px');
            eh = $('.event-info__title').height();
            $('.event-info__title').css('visibility', 'hidden');
        };
        $('.event-info__title').css('visibility', 'visible');
    }


    if (localStorage.getItem('lastShown') != (new Date()).getDate()) {
        if (localStorage.getItem("count") < 5) {
            // Заменить на 200000 перед релизом
            setTimeout(showModal, 200000);
            countVisits();
            console.log("Окно показано " + count + " раз");
        }
    }


    function countVisits() {
        str_count = localStorage.getItem("count");
        if (str_count == null || str_count == "null"){
            count = 0;
        } else {
            count = parseInt(str_count);
        }
        count++;
        localStorage.setItem("count", count);
    }

    function showModal() {
        $('#gradient_popup_wrapper').fadeIn();
        localStorage.setItem('lastShown', (new Date()).getDate());

        $('.popup_exit').click(function(){
            $('#gradient_popup_wrapper').fadeOut();
        })
    }

    //
    if ($('.events-item').length) {
        $('.events-item').each(function(i,e) {
            if ($(e).find('.event-cycle').length) {
                $(e).find('.event-type').css('margin-bottom', '10px')
            }
        });
    }

    // Cookie notify
    if (localStorage.getItem("state") != "accept") {
        $(".cookie-notify").fadeIn(300);
    };
    $("body").on("click", ".cookie-accept", function() {
        $(".cookie-notify").fadeOut(400);
        localStorage.setItem("state", "accept");
    });




    $('.raw_code_block>iframe').wrap('<div class="iframe_wrapper" />');

});
